import styled from "styled-components";
import { Row } from "react-styled-flexboxgrid";

export const StyledStandardPager = styled(Row)`
  padding: 2rem 0;
  > button {
    font-size: 0;
    width: 7px;
    height: 7px;
    background: var(--textColor);
    opacity: 0.3;
    border-radius: 100px;
    padding: 0;
    margin: 0 0.2rem;
    &.active {
      opacity: 1;
    }
  }
`;
