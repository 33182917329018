import styled from "styled-components";
import { Link } from "gatsby";
import is, { match, isNot } from "styled-is";

export const StyledHeading = styled.h2`
  --vMargin: ${(props) => props.vMargin || 0.5}em;

  font-family: var(--headingFont);
  color: var(--headingColor);
  line-height: var(--lhHeading);

  ${is("lhStandard")`
    line-height: var(--lhStandard);
  `}

  ${is("white")`
    color: white;
  `}  
  ${is("bodyColor")`
    color: var(--textColor);
  `}  
  ${is("uppercase")`
    text-transform: uppercase;
  `}
  ${is("fontBold")`
    --headingFont: var(--boldFont);
  `}
  ${is("bold")`
    font-weight:500;
  `}
  ${is("fontNormal")`
    font-weight:normal;
  `}
  ${is("underline")`
    text-decoration: underline;
  `}
  ${is("fontReg")`
    --headingFont: var(--bodyFont);
  `}
  
  ${is("featureFont")`
    --headingFont: var(--featureHeadingFont);
  `}
  > em {
    font-family: var(--featureHeadingFont);
  }
  /* Style Modifiers */
  ${is("ttu")`
    text-transform: uppercase;
  `}
  /* Margin Modifiers */
  ${isNot("noMargin")`
    margin-bottom: var(--vMargin);
  `}
  /* Aligment */
  ${is("center")`
    text-align: center;
    margin:0 auto;
  `}
  /* Size Modifiers */
  ${match("size", "h1")`
    font-size: var(--h1);
    --headingFont: var(--featureHeadingFont);
  `}
  ${match("size", "h2")`
    font-size: var(--h2);
    --headingFont: var(--featureHeadingFont);
  `}
  ${match("size", "h3")`
    font-size: var(--h3);
  `}
  ${match("size", "h4")`
    font-size: var(--h4);
  `}
  ${match("size", "h5")`
    font-size: var(--h5);
  `}
  ${match("size", "h6")`
    font-size: var(--h6);
  `}
  ${match("size", "h7")`
    font-size: var(--bodyText);
  `}
  ${match("size", "h8")`
    font-size: var(--smallText);
  `}
  /* Width Modifiers */
  ${is("measure")`
    max-width: var(--mHeading);
  `}
  ${is("measureWide")`
    max-width: var(--mStandard);
  `}
   /* Alignment Modifiers */
   ${is("centerText")`
    text-align: center;
  `}
   ${is("measure", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
   ${is("measureWide", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
`;

export const SmallTextButton = styled.button`
  font-family: var(--boldFont);
  text-transform: uppercase;
  font-size: var(--h6);
  ${is("active")`
    text-decoration: underline;
  `}
`;

export const SmallTextLink = styled(Link)`
  font-family: var(--boldFont);
  text-transform: uppercase;
  font-size: var(--h7);
  ${is("active")`
    text-decoration: underline;
    `}
  &.active {
    text-decoration: underline;
  }
`;
