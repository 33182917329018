import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  /* Root Vars */
  :root {
    /* Define Colors as colors */
    --black: #000000;
    --white: #ffffff;
    --green: #0C4425;
    --darkGreen: #09321B;    
    --forest: #85B336;    
    --bg: #F4F2ED; 
    --bgDark: #E9E6DF;   

    /* Define Colors intentions */
    --primary: var(--green);
    --reversed: var(--white);
    --svgFill: var(--green);
    --lineColor: var(--green);
    --bgColor: var(--bg);
    
    
    /* Dynamic Colors */
    --textColor: ${(props) =>
      props.reverseText ? "var(--reversed)" : "var(--green)"};
    --headingColor: ${(props) =>
      props.reverseText ? "var(--reversed)" : "var(--green)"};


    /* Styles */
    --lineThin: solid 1px var(--lineColor);
    --line: solid 2px var(--lineColor);
    --bcLine: 10rem;

    /* Type Familys */
    --headingFont: "Tiempos Text", sans-serif;

    --bodyFont: "Founders Grotesk", sans-serif;
    --boldFont: "Founders Grotesk", sans-serif;
    --mediumFont: "Founders Grotesk", sans-serif;

    /* Type Sizes */
    --h1: calc(50px + (90 - 50) * ((100vw - 320px) / (1800 - 320)));
    --h2: calc(34px + (67 - 34) * ((100vw - 320px) / (1800 - 320)));
    --h3: calc(26px + (50 - 26) * ((100vw - 320px) / (1800 - 320)));
    --h4: calc(24px + (38 - 24) * ((100vw - 320px) / (1800 - 320)));
    --h5: calc(20px + (28 - 20) * ((100vw - 320px) / (1800 - 320)));
    --h6: calc(18px + (21 - 18) * ((100vw - 320px) / (1800 - 320)));
    --bodyText: calc(16px + (18 - 16) * ((100vw - 320px) / (1800 - 320)));
    --smallText: calc(10px + (12 - 10) * ((100vw - 320px) / (1800 - 320)));

    /* Type Line Heights */
    --lhHeading: 1.5;
    --lhStandard: 1.7;
    /* Measures */
    --mHeading: 28em;
    --mStandard: 38em;

    /* Padding */
    --pH: 1.4rem;

    /* Container Widths */
    --cwNarrow: 1140px;
    --cwStandard: 1640px;

    /* Grid Column Gaps */
    --gridColGap: 4rem;
    --gridRowGap: 4rem;
  }

  /* Minimal Resets */
  html {  
    text-rendering: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-size: var(--bodyText);
    font-family: var(--bodyFont);
    line-height: var(--lhStandard);
    background: var(--bgColor);
    color: var(--textColor);
    transition: background-color 0.8s ease;
  }

  * {
    font-weight: normal;
    box-sizing: border-box;
    color: var(--textColor);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #___gatsby {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
      margin: 0;
      padding: 0;
      font-weight: normal;
  }

  h1, h2, h3 {    
  }

  button {
      cursor: pointer;
      background: none;
      border: none;
      font-size: inherit;
      font-family:var(--bodyFont);
      &:focus {
        outline:none;
      }
  }

  strong {
    font-family: var(--boldFont);
  }

  a {
    color: var(--textColor);
    text-decoration: none;
  }



  .sanity-portable-text {
    p,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom:0.6rem;
    }

    ul,
    ol {
      margin-left:20px;
    }

    strong {
      font-weight:bold;
    }
    a:not(.buttonLink) {
      text-decoration:underline;
      font-weight:inherit;
    }
    a.buttonLink {
      margin-top:1rem;
    }
  }

  
  /* Medium */
  @media screen and (min-width: 480px) {
    :root {
      /* Padding */
      --pH: 6vw;
    }
  }
  /* Large */
  @media screen and (min-width: 1024px) {
    :root {
      /* Styles */
      --pH: 7vw;
    }
  }
`;
