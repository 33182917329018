export const useSanityPath = (props) => {
  const { _type, main } = props || {};

  function getPath(_type, main) {
    const { slug } = main || {};
    switch (_type) {
      case "standardPage":
        return `/${slug.current}`;
      case "homePage":
        return `/`;
      default:
        return null;
    }
  }

  const path = getPath(_type, main);

  return path;
};
