import styled from "styled-components";
import { Link } from "gatsby";
import is from "styled-is";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const StyledButton = styled.button`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--bodyText);
  padding: 14px 60px;
  color: var(--textColor);
  text-decoration: underline;
  border: solid 1px var(--green1);
  border-radius: 50px;
  cursor: pointer;
  ${is("reversed")`
    color:white;
    `}
  ${is("mTop")`
    margin-top:2rem;
    `}
    ${is("mBot")`
    margin-bottom:2rem;
    `}
    ${is("flex")`
    display: flex;
    `}
    @media screen and (min-width:1024px) {
    transition: all 0.25s ease;
    &:hover {
      opacity: 0.5;
    }
  }
`;
export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  color: var(--textColor);
  text-decoration: underline;
  cursor: pointer;
  ${is("reversed")`
    color:white;
    `}
  ${is("flex")`
    display:flex;
    `}
    ${is("block")`
    display:block;
    `}
  ${is("mTop")`
    margin-top:2rem;
    `}
    ${is("mBot")`
    margin-bottom:2rem;
    `}
    @media screen and (min-width:1024px) {
    transition: all 0.25s ease;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const StyledAnchorLink = styled(AnchorLink)`
  font-size: 14px;
  font-weight: 500;
  color: var(--textColor);
  text-decoration: underline;
  cursor: pointer;
  ${is("reversed")`
    color:white;
    `}
  ${is("flex")`
    display:flex;
    `}
    ${is("block")`
    display:block;
    `}
  ${is("mTop")`
    margin-top:2rem;
    `}
    ${is("mBot")`
    margin-bottom:2rem;
    `}
    @media screen and (min-width:1024px) {
    transition: all 0.25s ease;
    &:hover {
      opacity: 0.5;
    }
  }
`;
