import { useState } from "react";

export const useToggle = (initial) => {
  const [isToggled, setToggled] = useState(initial);
  const toggle = () => setToggled((prevState) => !prevState);

  // If you want to rename output & multiple instance use array
  return [isToggled, setToggled, toggle];

  // Named Props & no order go with a named
  // return { isToggled, setToggled, toggle }
};
