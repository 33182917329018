import React from "react";
import { Link } from "gatsby";
import { match } from "styled-is";

import { useAppContext } from "../../state";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

// SVG
import LogoLight from "../../svg/logo-light.svg";
import LogoDark from "../../svg/logo-dark.svg";

const StyledHomeLogo = styled(Link)`
  height: auto;
  > div {
    width: 145px;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  #logo-dark {
    display: none;
  }
  ${match("reverseHeader", true)`
    #logo-dark {
      display:block;
    } 
    #logo-light {
      display:none;
    } 
  `}

  @media screen and (min-width: 1000px) {
    > div {
      width: 250px;
    }
  }
`;

export const HomeLogo = ({ reverseLogo, reverseLogoProduct, navOpen }) => {
  const { reverseHeader } = useAppContext();
  return (
    <StyledHomeLogo to="/" reverseHeader={reverseHeader}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={reverseLogo}
          initial={{ opacity: 0 }}
          animate={{ opacity: navOpen ? 0 : 1 }}
          exit={{ opacity: 0 }}
        >
          <LogoLight />
          <LogoDark />
        </motion.div>
      </AnimatePresence>
    </StyledHomeLogo>
  );
};
