import { useStaticQuery, graphql } from "gatsby";

export const useFooter = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    query {
      sanityFooter {
        footerText
        bottomNav {
          pageLink {
            linkText
            page {
              main {
                slug {
                  current
                }
              }
            }
          }
        }
        footerNav {
          heading
          items {
            ... on SanityNavLink {
              _key
              _type
              pageLink {
                page {
                  main {
                    slug {
                      current
                    }
                  }
                }
                linkText
              }
            }
            ... on SanityNavPath {
              _key
              _type
              linkText
              path
            }
            ... on SanitySocialLink {
              _key
              _type
              linkText
              url
            }
          }
        }
      }
    }
  `);
  return sanityFooter || [];
};
