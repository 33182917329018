import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { StandardContainer } from "../common";

const StyledStandardContainer = styled(StandardContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 6rem;
`;

const MenuRow = styled.div`
  width: calc(100% / 2);
  > div {
    grid-column: 2 / span 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    span {
      color: var(--forest);
    }
    svg {
      width: 21px;
      height: 21px;
    }
  }
  section {
    grid-column: 2 / span 12;
    color: white;
    padding-bottom: 0.7rem;
    a {
      font-size: 18px;
      color: white;
      display: block;
      margin: 0.8rem 0;
      font-family: var(--headingFont);
    }
  }
  @media screen and (min-width: 1024px) {
    grid-column: 7 / span 8;
  }
`;

export const FooterMenuDesk = ({ footerNav }) => {
  return (
    <StyledStandardContainer>
      {footerNav && (
        <>
          {footerNav?.map((item) => {
            return (
              <MenuRow>
                <div>
                  <span>{item.heading}</span>
                </div>
                <section>
                  {item?.items?.map((menuItem) => {
                    return (
                      <>
                        {menuItem._type === "navPath" && (
                          <a href={"/" + menuItem.path}>{menuItem.linkText}</a>
                        )}
                        {menuItem._type === "navLink" && (
                          <Link
                            to={
                              "/" +
                              menuItem?.pageLink?.page?.main?.slug?.current
                            }
                          >
                            {menuItem.pageLink.linkText}
                          </Link>
                        )}
                        {menuItem._type === "socialLink" && (
                          <a
                            href={menuItem.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {menuItem.linkText}
                          </a>
                        )}
                      </>
                    );
                  })}
                </section>
              </MenuRow>
            );
          })}
        </>
      )}
    </StyledStandardContainer>
  );
};
