import React, { createContext, useContext } from "react";
// Hooks
import { useToggle } from "../hooks";

export const AppContext = createContext({
  navOpen: false,
  reverseHeader: false,
});

// const isBrowser = typeof window !== "undefined"

export const PageWrapper = ({ children }) => {
  // Nav
  const [navOpen, setNavOpen, toggleMenu] = useToggle(false); // eslint-disable-line

  // Pin Header
  const [headerPinned, setHeaderPinned] = useToggle(false); // eslint-disable-line

  // Hidden Header Footer
  const [hiddenHeaderFooter, setHiddenHeaderFooter] = useToggle(false); // eslint-disable-line

  // Header Reverse
  // eslint-disable-next-line
  const [reverseHeader, setReverseHeader] = useToggle(false);

  const pinHeader = () => {
    setHeaderPinned(true);
  };
  const unpinHeader = () => {
    setHeaderPinned(false);
  };
  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        navOpen,
        toggleMenu,
        reverseHeader,
        setReverseHeader,
        headerPinned,
        pinHeader,
        unpinHeader,
        closeNav,
        hiddenHeaderFooter,
        setHiddenHeaderFooter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
