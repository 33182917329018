import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import Div100vh from "react-div-100vh";
import { Link } from "gatsby";
import { useNavigation } from "../../GraphQl/useNavigation";
import { useWindowSize, useScrollFreeze } from "../../hooks";
import { useAppContext } from "../../state";
import BgMobile from "../../images/topo-menu-mob.png";
import BgDesk from "../../images/topo-menu-desk.png";

const MenuWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  /* bottom:0; */
  height: 100%;
  width: 100%;
  background: var(--darkGreen);
  z-index: 9998;
  .topo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    a {
      font-family: var(--headingFont);
      font-size: calc(38px + (40 - 30) * ((100vw - 320px) / (1800 - 320)));
      color: white;
      display: block;
      margin: 1rem 0;
    }
  }
  @media screen and (min-width: 1024px) {
    > div {
      flex-direction: row;
      a {
        margin: 0 2.5rem;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    > div {
      a {
        margin: 0 4rem;
      }
    }
  }
`;

// Animation
const wrapperVariants = {
  open: {
    opacity: 1,
    transition: { duration: 0.4 },
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const navVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const NavInner = ({ navOpen }) => {
  useScrollFreeze();

  const sanityNavigation = useNavigation();

  const { width } = useWindowSize();

  const { toggleMenu } = useAppContext();

  return (
    <MenuWrapper
      variants={wrapperVariants}
      initial="closed"
      animate="open"
      exit="closed"
    >
      {width < 768 && <img className="topo" src={BgMobile} alt="Projects" />}
      {width > 767 && <img className="topo" src={BgDesk} alt="Projects" />}
      <Div100vh style={{ height: "100rvh" }}>
        {sanityNavigation.mainNav &&
          sanityNavigation.mainNav.map((menuItem, index) => {
            return (
              <motion.div variants={navVariants}>
                {menuItem._type === "navPath" && (
                  <Link to={"/" + menuItem.path} onClick={toggleMenu}>
                    {menuItem.linkText}
                  </Link>
                )}
                {menuItem._type === "navLink" && (
                  <Link
                    to={"/" + menuItem.pageLink.page.main.slug.current}
                    onClick={toggleMenu}
                  >
                    {menuItem.pageLink.linkText}
                  </Link>
                )}
              </motion.div>
            );
          })}
      </Div100vh>
    </MenuWrapper>
  );
};

export const Menu = () => {
  const { navOpen } = useAppContext();

  return (
    <>
      <AnimatePresence>
        {navOpen && <NavInner navOpen={navOpen} />}
      </AnimatePresence>
    </>
  );
};
