import { useStaticQuery, graphql } from "gatsby";

export const useNavigation = () => {
  const { sanityNavigation } = useStaticQuery(graphql`
    query {
      sanityNavigation {
        mainNav {
          ... on SanityNavLink {
            _key
            _type
            pageLink {
              linkText
              page {
                main {
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityNavPath {
            _key
            _type
            linkText
            path
          }
        }
      }
    }
  `);
  return sanityNavigation || [];
};
