import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";

// Hooks, Querys & State
import { useAppContext } from "../../state";

// Import components
import { Header } from "../nav/header";
import { Footer } from "../nav/footer";

// Styles & Fonts
import "../../styles/fonts.css";
import { GlobalStyles } from "../../styles/globalStyles";

const StyledMainWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 100%;
`;

const theme = {
  flexboxgrid: {
    // Defaults
    gutterWidth: 0,
  },
};

const Layout = ({ children }) => {
  const { hiddenHeaderFooter } = useAppContext();
  const path = globalHistory.location.pathname;
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {!hiddenHeaderFooter && <Header />}
        <AnimatePresence exitBeforeEnter>
          <StyledMainWrapper
            key={path}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: "easeInOut",
              duration: 0.25,
            }}
          >
            {children}
            {!hiddenHeaderFooter && <Footer />}
          </StyledMainWrapper>
        </AnimatePresence>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
