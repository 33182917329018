import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Hooks, Querys & Context
import { useFooter } from "../../GraphQl/useFooter";
import { useWindowSize } from "../../hooks";

// Components
import { FooterMenuMobile } from "./footerMenuMobile";
import { FooterMenuDesk } from "./footerMenuDesk";
import { GridContainer, StandardContainer, StyledHeading } from "../common";

// SVG
import LogoIcon from "../../svg/logo-icon.svg";

const StyledFooter = styled(GridContainer)`
  background: var(--darkGreen);
  color: white;
`;

const TopBar = styled(GridContainer)`
  grid-template-columns: repeat(12, 1fr);
  grid-row: 1;
  grid-column: 2 / span 12;
  padding: 2rem 0;
  svg {
    grid-column: 1 / span 3;
    max-width: 120px;
    height: auto;
  }
  h3 {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1800 - 320)));
    grid-column: 5 / span 10;
  }
  @media screen and (min-width: 1024px) {
    padding: 10rem 0;
    h3 {
      grid-column: 6 / span 9;
    }
  }
`;

const MenuBar = styled(StandardContainer)`
  grid-column: 1 / span 14;
  grid-row: 3;
  @media screen and (min-width: 768px) {
    grid-column: 2 / span 12;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 7 / span 8;
  }
`;

const BottomBar = styled(StandardContainer)`
  border-top: solid white 1px;
  grid-row: 4;
  grid-column: 2 / span 12;
  padding: 2rem 0;
  font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1800 - 320)));
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 30px;
  text-align: center;
  --textColor: white;
  a {
    display: block;
  }
  span {
    display: inline-block;
    margin: 0 0.7rem;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    a {
      display: inline-block;
      padding: 5px 0 0 1rem;
      margin-left: 1rem;
      border-left: solid 1px white;
      &:nth-of-type(1) {
        padding: 5px 0 0 0;
        margin-left: 0;
        border-left: none;
      }
    }
  }
`;

export const Footer = () => {
  const sanityFooter = useFooter();

  const { width } = useWindowSize();

  return (
    <StyledFooter>
      <TopBar>
        <LogoIcon />
        <StyledHeading as="h3" white noMargin>
          {sanityFooter.footerText}
        </StyledHeading>
      </TopBar>
      <MenuBar>
        {width < 768 && <FooterMenuMobile footerNav={sanityFooter.footerNav} />}
        {width > 767 && <FooterMenuDesk footerNav={sanityFooter.footerNav} />}
      </MenuBar>
      <BottomBar>
        <div>
          {sanityFooter.bottomNav.map((item) => {
            return (
              <>
                <Link to={"/" + item.pageLink.page.main.slug.current}>
                  {item.pageLink.linkText}
                </Link>
              </>
            );
          })}
        </div>
        <div>COPYRIGHT © 2020 ELANDNZ LTD</div>
      </BottomBar>
    </StyledFooter>
  );
};
