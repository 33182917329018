import React from "react";
import styled from "styled-components";

import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

export const StyledCoverBackground = styled(BackgroundImage)`
  --hX: ${(props) =>
    (props.hotspot && `${props.hotspot.x * 100}%`) || "center"};
  --hY: ${(props) =>
    (props.hotspot && `${props.hotspot.y * 100}%`) || "center"};

  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;

  background-position: var(--hX) var(--hY) !important;
`;

export const PlaceHolderImage = styled.div`
  --aR: ${(props) => props.padBot || 100}%;
  width: 100%;
  height: 0px;
  padding-bottom: var(--aR);
  background: var(--navy2);
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    content: "No image added";
    font-weight: 500;
  }
`;

export const HotspotImage = styled((props) => (
  <Img className="hotspot-image" {...props} />
))`
  --hX: ${(props) =>
    (props.hotspot && `${props.hotspot.x * 100}%`) || "center"};
  --hY: ${(props) =>
    (props.hotspot && `${props.hotspot.y * 100}%`) || "center"};
  --aR: ${(props) => props.padBot || 100}%;

  width: 100%;
  height: 0px;
  padding-bottom: var(--aR);
  position: relative;
  img {
    object-position: var(--hX) var(--hY) !important;
  }
  &.is-selected {
    opacity: 1;
  }
`;
