import styled from "styled-components";

export const StyledInput = styled.input`
  background: transparent;
  grid-row: 1;
  grid-column: 1 / span 2;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--textColor);
  font-family: var(--mediumFont);
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1800 - 320)));
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  &.textarea {
    height: 140px;
  }
  &:focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--textColor);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--textColor);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--textColor);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--textColor);
  }
`;
