import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";

// SVG
import Expand from "../../svg/down.svg";

// Components
import { GridContainer } from "../common";

const MobileMenu = styled.div``;

const RowHeading = styled.div``;

const StyledMenuRow = styled(GridContainer)`
  border-top: solid #0c4425 1px;
  grid-column: 1 / span 14;
  > div {
    grid-column: 2 / span 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 0;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    span {
      color: var(--forest);
    }
    svg {
      width: 21px;
      height: 21px;
    }
  }
  section {
    grid-column: 2 / span 12;
    color: white;
    padding-bottom: 0.7rem;
    display: none;
    a {
      font-size: 18px;
      color: white;
      display: block;
      margin: 0.8rem 0;
      font-family: var(--headingFont);
    }
  }
  @media screen and (min-width: 1024px) {
    border-top: none;
  }
`;

const MenuRow = ({ i, expanded, setExpanded }) => {
  const isOpen = i === expanded;

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <StyledMenuRow>
      <RowHeading
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <span>{i.heading}</span>
        <Expand />
      </RowHeading>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.3,
              ease: [0.4, 0.7, 0.23, 0.98],
            }}
          >
            {i.items.map((menuItem, index) => {
              return (
                <>
                  {menuItem._type === "navPath" && (
                    <a href={menuItem.path}>{menuItem.linkText}</a>
                  )}
                  {menuItem._type === "navLink" && (
                    <Link to={menuItem.pageLink.page.main.slug.current}>
                      {menuItem.pageLink.linkText}
                    </Link>
                  )}
                  {menuItem._type === "socialLink" && (
                    <a href={menuItem.url} target="_blank" rel="noreferrer">
                      {menuItem.linkText}
                    </a>
                  )}
                </>
              );
            })}
          </motion.section>
        )}
      </AnimatePresence>
    </StyledMenuRow>
  );
};

export const FooterMenuMobile = ({ footerNav }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <MobileMenu>
      {footerNav && (
        <>
          {footerNav.map((i, index) => (
            <MenuRow i={i} expanded={expanded} setExpanded={setExpanded} />
          ))}
        </>
      )}
    </MobileMenu>
  );
};
