import React from "react";
import styled from "styled-components";
import Headroom from "react-headroom";
import { match } from "styled-is";

// Hooks
import { useAppContext } from "../../state";

// Components
import { HomeLogo } from "./homeLogo";
import { Menu } from "./menu";
import { FlexContainer } from "../common";

// SVG
import MenuIcon from "../../svg/menu.svg";
import CloseIcon from "../../svg/cross.svg";

const StyledHeader = styled((props) => <Headroom {...props} />)`
  position: relative;
  width: 100%;
  z-index: 9999;
  header {
    padding: 1rem;
    align-items: center;
  }
  .headroom--pinned {
    background: var(--darkGreen);
    #logo-dark {
      display: none !important;
    }
    #logo-light {
      display: block !important;
    }
    ${match("isOpen", true)`
      background:transparent;    
    `}
  }
  ${match("isOpen", true)` 
    #logo-dark {
      display: none !important;
    }
    #logo-light {
      display: block !important;
    } 
  `}

  @media screen and (min-width: 768px) {
    header {
      padding: 3rem 3rem;
    }
  }
`;

export const Header = () => {
  const { toggleMenu, navOpen, reverseHeader } = useAppContext();
  return (
    <>
      <StyledHeader isOpen={navOpen} reverseHeader={reverseHeader}>
        <FlexContainer between="xs" as="header">
          <HomeLogo />
          {navOpen ? (
            <>
              <CloseIcon isOpen={navOpen} onClick={toggleMenu} />
            </>
          ) : (
            <>
              <MenuIcon isOpen={navOpen} onClick={toggleMenu} />
            </>
          )}
        </FlexContainer>
      </StyledHeader>
      <Menu />
    </>
  );
};
