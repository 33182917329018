import styled from "styled-components";
import is from "styled-is";
import { Row } from "react-styled-flexboxgrid";

export const StandardContainer = styled.div`
  /* Vars */
  --marginTop: ${(props) => props.marginTop / 2 || 0}px;
  --marginBottom: ${(props) => props.marginBottom / 2 || 0}px;

  --padTop: ${(props) => props.padTop / 2 || 0}px;
  --padBottom: ${(props) => props.padBottom / 2 || 0}px;

  position: relative;
  width: 100%;
  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);

  padding-top: var(--padTop);
  padding-bottom: var(--padBottom);
  transition: all 0.2s ease;

  /* Padding Modifiers */
  ${is("padH")`
     padding-left: var(--pH);
     padding-right: var(--pH);
  `}
  /* Wrapper Modifiers */
  ${is("wrappedStandard")`
    max-width: var(--cwStandard);
    margin-left: auto;
    margin-right: auto;
  `}
  ${is("wrappedNarrow")`
    max-width: var(--cwNarrow);
    margin-left: auto;
    margin-right: auto;
  `}
  /* Medium */
  @media screen and (min-width: 480px) {
    --marginTop: ${(props) => props.marginTop || 0}px;
    --marginBottom: ${(props) => props.marginBottom || 0}px;

    --padTop: ${(props) => props.padTop || 0}px;
    --padBottom: ${(props) => props.padBottom || 0}px;
  }
`;

export const GridContainer = styled.div`
  /* Vars */
  --marginTop: ${(props) => props.marginTop / 2 || 0}px;
  --marginBottom: ${(props) => props.marginBottom / 2 || 0}px;

  --padTop: ${(props) => props.padTop / 2 || 0}px;
  --padBottom: ${(props) => props.padBottom / 2 || 0}px;

  --bgColor: ${(props) => props.bgColor};

  position: relative;
  width: 100%;
  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);

  padding-top: var(--padTop);
  padding-bottom: var(--padBottom);
  transition: all 0.2s ease;

  /* background-color: var(--bgColor); */

  display: grid;
  grid-template-columns: var(--pH) repeat(12, 1fr) var(--pH);

  ${is("singleItem")`
    > article {
      grid-column: 1 / span 14; 
    }
  `}

  ${is("singleItem", "wrapped")`
    > article {
      grid-column: 2 / span 12; 
    }
    /* Large */
    @media screen and (min-width: 1024px) {
      > article {
        grid-column: 3 / span 10;
      }
    }
  `}

  ${is("singleItem", "wrappedNarrow")`
    > article {
      grid-column: 2 / span 12; 
    }
    /* Large */
    @media screen and (min-width: 1024px) {
      > article {
        grid-column: 4 / span 8;
      }
    }
    /* X-Large */
    @media screen and (min-width: 1500px) {
      > article {
        grid-column: 5 / span 6;
      }
    }
  `}

  /* Medium */
  @media screen and (min-width: 480px) {
    --marginTop: ${(props) => props.marginTop || 0}px;
    --marginBottom: ${(props) => props.marginBottom || 0}px;

    --padTop: ${(props) => props.padTop || 0}px;
    --padBottom: ${(props) => props.padBottom || 0}px;
  }
`;

export const FlexContainer = styled(Row)`
  /* Vars */
  --marginTop: ${(props) => props.marginTop / 2 || 0}px;
  --marginBottom: ${(props) => props.marginBottom / 2 || 0}px;

  --padTop: ${(props) => props.padTop / 2 || 0}px;
  --padBottom: ${(props) => props.padBottom / 2 || 0}px;

  position: relative;
  width: 100%;
  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);

  padding-top: var(--padTop);
  padding-bottom: var(--padBottom);

  /* Padding Modifiers */
  ${is("padH")`
     padding-left: var(--pH);
     padding-right: var(--pH);
  `}
  /* Wrapper Modifiers */
  ${is("wrappedStandard")`
    max-width: var(--cwStandard);
    margin-left: auto;
    margin-right: auto;
  `}
  ${is("wrappedNarrow")`
    max-width: var(--cwNarrow);
    margin-left: auto;
    margin-right: auto;
  `}
  /* Medium */
  @media screen and (min-width: 480px) {
    --marginTop: ${(props) => props.marginTop || 0}px;
    --marginBottom: ${(props) => props.marginBottom || 0}px;

    --padTop: ${(props) => props.padTop || 0}px;
    --padBottom: ${(props) => props.padBottom || 0}px;
  }
`;
